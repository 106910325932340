<template>
  <div>
    <div v-if="openref">
      <a href="#" @click="openReference"
        ><i class="fas fa-external-link-alt"></i>
        {{ field.Metadata.Description }}
      </a>
    </div>
    <div v-else>
      {{ field.Metadata.Description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {field: {required: true, type: Object}},
  computed: {
    specification() {
      return this.field.Combobox.specification;
    },
    openref() {
      if (this.specification.openRef === true) return true;

      return false;
    },
    openrefInput() {
      const input = {
        Prefix: "Single",
        Subject: this.specification.tableName,
        Criteria: [],
      };

      const Criteria = {};

      Criteria[this.specification.columnName] = this.field.Value;

      input.Criteria.push(Criteria);

      return input;
    },
  },
  methods: {
    openReference() {
      global.session.openWindow(this.openrefInput);
    },
  },
};
</script>
